<script setup>
import { ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import KanbanAPI from '../../../../api/kanban';
import KanbanColumn from './KanbanColumn.vue';
import KanbanHeader from './KanbanHeader.vue';
import { useStore } from 'vuex';
import KanbanItemForm from './KanbanItemForm.vue';
import Modal from '../../../../components/Modal.vue';
import KanbanItemDetails from './KanbanItemDetails.vue';
import KanbanFilter from './KanbanFilter.vue';
import { emitter } from 'shared/helpers/mitt';

const props = defineProps({
  currentView: {
    type: String,
    default: 'kanban',
  },
  kanbanItems: {
    type: Array,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
  agentsList: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['switch-view', 'items-updated']);
const { t } = useI18n();
const store = useStore();

// Estado
const error = computed(() => store.state.kanban.error);
const columns = ref([]);
const showAddModal = ref(false);
const selectedColumn = ref(null);
const selectedFunnel = computed(
  () => store.getters['funnel/getSelectedFunnel']
);
const showDeleteModal = ref(false);
const itemToDelete = ref(null);
const showEditModal = ref(false);
const itemToEdit = ref(null);
const showDetailsModal = ref(false);
const itemToShow = ref(null);
const activeFilters = ref(null);
const showFilterModal = ref(false);
const filteredResults = ref({ total: 0, stages: {} });
const mountKey = ref(0);
const isUpdating = ref(false);

// Funções
const updateColumns = () => {
  const funnel = store.getters['funnel/getSelectedFunnel'];
  if (!funnel?.stages) return;

  columns.value = Object.entries(funnel.stages)
    .map(([id, stage]) => ({
      id,
      title: stage.name,
      color: stage.color,
      description: stage.description,
      position: stage.position,
      items: props.kanbanItems.filter(item => item.funnel_stage === id),
    }))
    .sort((a, b) => a.position - b.position);
};

const handleAdd = columnId => {
  selectedColumn.value = columns.value.find(col => col.id === columnId);
  showAddModal.value = true;
};

const handleDelete = async itemToDeleteParam => {
  try {
    isUpdating.value = true;
    await KanbanAPI.deleteItem(itemToDeleteParam.id);
    emit('items-updated');
    emitter.emit('newToastMessage', {
      message: 'Item excluído com sucesso',
      action: { type: 'success' },
    });
    showDeleteModal.value = false;
    itemToDelete.value = null;
  } catch (err) {
    emitter.emit('newToastMessage', {
      message: err.response?.data?.message || t('KANBAN.ERROR_DELETING_ITEM'),
      action: { type: 'error' },
    });
  } finally {
    isUpdating.value = false;
  }
};

const handleDrop = async ({ itemId, columnId }) => {
  if (!itemId || !columnId) return;

  try {
    isUpdating.value = true;
    const sourceColumn = columns.value.find(col =>
      col.items.some(i => i.id === itemId)
    );
    const targetColumn = columns.value.find(col => col.id === columnId);

    if (sourceColumn && targetColumn) {
      const draggedItem = sourceColumn.items.find(i => i.id === itemId);
      if (draggedItem) {
        sourceColumn.items = sourceColumn.items.filter(i => i.id !== itemId);
        targetColumn.items = [
          ...targetColumn.items,
          { ...draggedItem, funnel_stage: columnId },
        ].sort((a, b) => (a.position || 0) - (b.position || 0));
      }
    }

    await KanbanAPI.moveToStage(itemId, columnId);
    emit('items-updated');
    emitter.emit('newToastMessage', {
      message: 'Item movido com sucesso',
      action: { type: 'success' },
    });
  } catch (err) {
    emitter.emit('newToastMessage', {
      message: err.response?.data?.message || t('KANBAN.ERROR_MOVING_ITEM'),
      action: { type: 'error' },
    });
  } finally {
    isUpdating.value = false;
  }
};

const handleSettings = () => {
  // TODO: Implementar configurações do Kanban
  emitter.emit('newToastMessage', {
    message: t('KANBAN.SETTINGS_NOT_IMPLEMENTED'),
    action: { type: 'info' },
  });
};

const handleItemCreated = async item => {
  if (!item) return;
  showAddModal.value = false;
  selectedColumn.value = null;
  emit('items-updated');
};

const handleSearch = () => {
  // TODO: Implementar busca
  emitter.emit('newToastMessage', {
    message: t('KANBAN.SEARCH_NOT_IMPLEMENTED'),
    action: { type: 'info' },
  });
};

const handleFilterClose = () => {
  showFilterModal.value = false;

  // Se não houver filtros ativos, recarregar os itens
  if (!activeFilters.value) {
    store.dispatch('kanban/fetchKanbanItems');
  }
};

const handleFilter = filters => {
  if (!filters) {
    activeFilters.value = null;
    store.dispatch('kanban/fetchKanbanItems');
    return;
  }

  activeFilters.value = filters;
  // Atualiza os resultados filtrados
  filteredResults.value = {
    total: props.kanbanItems.length,
    stages: {},
  };
};

const handleEdit = item => {
  itemToEdit.value = item;
  showEditModal.value = true;
};

const handleItemClick = item => {
  itemToShow.value = item;
  showDetailsModal.value = true;
};

const handleEditFromDetails = item => {
  showDetailsModal.value = false;
  handleEdit(item);
};

const handleDetailsUpdate = async () => {
  emit('items-updated');
};

const handleConversationUpdated = ({ itemId, conversation }) => {
  // Atualiza os dados da conversa no item
  const item = props.kanbanItems.find(i => i.id === itemId);
  if (item) {
    item.item_details = {
      ...item.item_details,
      conversation,
    };
  }
};

const handleItemEdited = async item => {
  if (!item) return;
  showEditModal.value = false;
  itemToEdit.value = null;
  emit('items-updated');
};

// Watches
watch(
  () => props.kanbanItems,
  () => {
    updateColumns();
  }
);

watch(
  () => selectedFunnel.value,
  () => {
    updateColumns();
  }
);

// Inicialização
updateColumns();

// Adicionar listener para atualização do kanban
emitter.on('kanban.item.updated', () => {
  store.dispatch('kanban/fetchKanbanItems');
});

// Computed
const userHasAccess = computed(() => {
  const currentUser = store.getters.getCurrentUser;
  const currentFunnel = store.getters['funnel/getSelectedFunnel'];

  if (!currentFunnel || !currentUser) return false;
  if (currentUser.role === 'administrator') return true;

  return currentFunnel.settings?.agents?.some(
    agent => agent.id === currentUser.id
  );
});
</script>

<template>
  <div :key="mountKey" class="flex flex-col h-full bg-white dark:bg-slate-900">
    <KanbanHeader
      :current-view="currentView"
      :current-stage="selectedColumn?.id"
      :search-results="filteredResults"
      :columns="columns"
      :active-filters="activeFilters"
      :kanban-items="kanbanItems"
      :agents-list="agentsList"
      @filter="showFilterModal = true"
      @settings="handleSettings"
      @item-created="handleItemCreated"
      @items-updated="() => store.dispatch('kanban/fetchKanbanItems')"
      @search="handleSearch"
      @switch-view="view => emit('switch-view', view)"
    />

    <div v-if="isLoading" class="flex justify-center items-center flex-1">
      <span class="loading-spinner" />
    </div>

    <div
      v-else-if="error"
      class="flex justify-center items-center flex-1 text-ruby-500"
    >
      {{ error }}
    </div>

    <div
      v-else-if="!userHasAccess"
      class="flex flex-col items-center justify-center flex-1 p-8 text-center"
    >
      <div class="w-16 h-16 mb-4 text-slate-400">
        <fluent-icon icon="lock-closed" size="64" />
      </div>
      <h3 class="text-xl font-medium text-slate-700 dark:text-slate-300 mb-2">
        {{ t('KANBAN.ACCESS_RESTRICTED') }}
      </h3>
      <p class="text-sm text-slate-600 dark:text-slate-400 max-w-md">
        {{ t('KANBAN.ACCESS_RESTRICTED_MESSAGE') }}
      </p>
    </div>

    <div v-else class="flex-1 overflow-x-auto kanban-columns">
      <div
        v-if="isUpdating"
        class="absolute top-2 right-2 flex items-center text-sm text-slate-500"
      >
        <span class="loading-spinner-small mr-2" />
        {{ t('KANBAN.UPDATING') }}
      </div>

      <div class="flex h-full p-4 space-x-4">
        <KanbanColumn
          v-for="column in columns"
          :key="column.id"
          :id="column.id"
          :title="column.title"
          :color="column.color"
          :items="column.items"
          :agents-list="agentsList"
          :count="column.items.length"
          :total-columns="columns.length"
          @add="handleAdd"
          @edit="handleEdit"
          @delete="handleDelete"
          @drop="handleDrop"
          @item-click="handleItemClick"
        />
      </div>
    </div>

    <!-- Modais -->
    <Modal
      v-model:show="showAddModal"
      size="full-width"
      :on-close="() => (showAddModal = false)"
    >
      <div class="p-6">
        <h3 class="text-lg font-medium">
          {{ t('KANBAN.ADD_ITEM') }}
        </h3>
        <KanbanItemForm
          v-if="selectedColumn && selectedFunnel"
          :funnel-id="selectedFunnel.id"
          :stage="selectedColumn.id"
          :position="selectedColumn.position"
          @saved="handleItemCreated"
          @close="showAddModal = false"
        />
        <div v-else class="text-center text-red-500">
          {{ t('KANBAN.ERRORS.NO_FUNNEL_SELECTED') }}
        </div>
      </div>
    </Modal>

    <Modal
      v-model:show="showDeleteModal"
      :on-close="
        () => {
          showDeleteModal = false;
          itemToDelete = null;
        }
      "
    >
      <div class="p-6">
        <h3 class="text-lg font-medium mb-4">
          {{ t('KANBAN.DELETE_CONFIRMATION.TITLE') }}
        </h3>
        <p class="text-sm text-slate-600 dark:text-slate-400 mb-6">
          {{
            t('KANBAN.DELETE_CONFIRMATION.MESSAGE', {
              item: itemToDelete?.title,
            })
          }}
        </p>
        <div class="flex justify-end space-x-2">
          <woot-button
            variant="clear"
            color-scheme="secondary"
            @click="showDeleteModal = false"
          >
            {{ t('CANCEL') }}
          </woot-button>
          <woot-button
            variant="solid"
            color-scheme="alert"
            @click="handleDelete(itemToDelete)"
          >
            {{ t('DELETE') }}
          </woot-button>
        </div>
      </div>
    </Modal>

    <Modal
      v-model:show="showEditModal"
      size="full-width"
      :on-close="
        () => {
          showEditModal = false;
          itemToEdit = null;
        }
      "
    >
      <div class="p-6">
        <h3 class="text-lg font-medium mb-4">
          {{ t('KANBAN.EDIT_ITEM') }}
        </h3>
        <KanbanItemForm
          v-if="itemToEdit && selectedFunnel"
          :funnel-id="selectedFunnel.id"
          :stage="itemToEdit.funnel_stage"
          :position="itemToEdit.position"
          :initial-data="itemToEdit"
          is-editing
          @saved="handleItemEdited"
          @close="showEditModal = false"
        />
      </div>
    </Modal>

    <Modal
      v-model:show="showDetailsModal"
      size="full-width"
      :on-close="
        () => {
          showDetailsModal = false;
          itemToShow = null;
          handleDetailsUpdate();
        }
      "
    >
      <KanbanItemDetails
        v-if="itemToShow"
        :item="itemToShow"
        :agents-list="agentsList"
        @close="
          () => {
            showDetailsModal = false;
            itemToShow = null;
            handleDetailsUpdate();
          }
        "
        @edit="handleEditFromDetails"
        @item-updated="handleDetailsUpdate"
      />
    </Modal>

    <KanbanFilter
      :show="showFilterModal"
      :columns="columns"
      :filtered-results="filteredResults"
      :current-funnel="store.getters['funnel/getSelectedFunnel']"
      :agents-list="agentsList"
      @close="handleFilterClose"
      @apply="handleFilter"
    />
  </div>
</template>

<style scoped>
.flex-1 {
  min-height: 0;
}

.kanban-columns {
  scrollbar-width: thin;
  scrollbar-color: var(--color-woot) var(--color-background-light);

  &::-webkit-scrollbar {
    height: 8px;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: var(--color-background-light);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-woot);
    border-radius: 4px;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loading-spinner-small {
  width: 16px;
  height: 16px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

:root {
  --color-background-light: #f1f5f9;
}

.dark {
  --color-background-light: #1e293b;
}
</style>
