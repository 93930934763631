<script setup>
import { ref, onMounted, watch, computed, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import KanbanTab from './components/KanbanTab.vue';
import ListTab from './components/ListTab.vue';
import AgendaTab from './components/AgendaTab.vue';
import FunnelsManager from './components/FunnelsManager.vue';
import MessageTemplates from './components/MessageTemplates.vue';
import KanbanAPI from '../../../api/kanban';
import agents from '../../../api/agents';
import conversationAPI from '../../../api/inbox/conversation';

const store = useStore();
const currentView = ref('kanban');
const components = {
  kanban: KanbanTab,
  list: ListTab,
  agenda: AgendaTab,
  funnels: FunnelsManager,
  templates: MessageTemplates,
};

const isLoading = computed(() => store.state.kanban.loading);
const selectedFunnel = computed(
  () => store.getters['funnel/getSelectedFunnel']
);
const localKanbanItems = ref([]);
const agentsList = ref([]);
const conversationsMap = ref({});
const conversationsCache = ref({});

// Modificar a estrutura para evitar chamadas duplicadas
const isInitialLoad = ref(true);
const isLoadingData = ref(false);

// Função para buscar conversa
const fetchConversation = async conversationId => {
  if (conversationsCache.value[conversationId]) {
    return conversationsCache.value[conversationId];
  }

  try {
    const { data } = await conversationAPI.show(conversationId);
    conversationsCache.value[conversationId] = data;
    return data;
  } catch (error) {
    return null;
  }
};

// Função principal para carregar todos os dados necessários
const loadInitialData = async () => {
  if (!selectedFunnel.value?.id || isLoadingData.value) return;

  try {
    isLoadingData.value = true;

    // Carregar dados em paralelo
    const [kanbanResponse, agentsResponse] = await Promise.all([
      KanbanAPI.getItems(selectedFunnel.value.id),
      agents.get(),
    ]);

    // Atualizar lista de agentes
    agentsList.value = agentsResponse.data;

    // Processar itens do kanban e buscar conversas necessárias
    const conversationIds = kanbanResponse.data
      .filter(item => item.item_details?.conversation_id)
      .map(item => item.item_details.conversation_id);

    // Buscar apenas conversas únicas que não estão no cache
    const uniqueConversationIds = [...new Set(conversationIds)].filter(
      id => !conversationsCache.value[id]
    );

    if (uniqueConversationIds.length > 0) {
      const conversationPromises = uniqueConversationIds.map(fetchConversation);
      await Promise.all(conversationPromises);
    }

    // Montar o mapa de conversas usando o cache
    conversationsMap.value = conversationIds.reduce((acc, id) => {
      if (conversationsCache.value[id]) {
        acc[id] = conversationsCache.value[id];
      }
      return acc;
    }, {});

    // Processar itens do kanban
    localKanbanItems.value = kanbanResponse.data.map(item => {
      const date = new Date(item.created_at);
      const formattedDate = new Intl.DateTimeFormat('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }).format(date);

      return {
        id: item.id,
        title: item.item_details.title || '',
        description: item.item_details.description || '',
        priority: item.item_details.priority || 'none',
        assignee: item.item_details.assignee || null,
        position: item.position,
        funnel_stage: item.funnel_stage,
        item_details: {
          ...item.item_details,
          conversation:
            conversationsMap.value[item.item_details?.conversation_id],
        },
        custom_attributes: item.custom_attributes || {},
        created_at: item.created_at,
        createdAt: formattedDate,
      };
    });
  } catch (error) {
    console.error('Erro ao carregar dados:', error);
  } finally {
    isLoadingData.value = false;
    isInitialLoad.value = false;
  }
};

// Modificar o watch para usar a nova função
watch(
  selectedFunnel,
  async newFunnel => {
    if (newFunnel?.id) {
      await loadInitialData();
    }
  },
  { immediate: true }
);

// Modificar onMounted para usar a nova função
onMounted(async () => {
  if (selectedFunnel.value?.id) {
    await loadInitialData();
  }
});

onUnmounted(() => {
  conversationsCache.value = {};
});

const switchView = view => {
  currentView.value = view;
};

// Modificar handleItemsUpdated para reutilizar o cache
const handleItemsUpdated = async () => {
  await loadInitialData();
};
</script>

<template>
  <div class="flex h-full w-full overflow-hidden">
    <keep-alive>
      <component
        :is="components[currentView]"
        :current-view="currentView"
        :kanban-items="localKanbanItems"
        :agents-list="agentsList"
        :conversations-map="conversationsMap"
        :is-loading="isLoading"
        @switch-view="switchView"
        @items-updated="handleItemsUpdated"
      />
    </keep-alive>
  </div>
</template>

<style scoped>
.flex {
  min-height: 0;
  min-width: 0;
  height: 100%;
  width: 100%;
}
</style>
