<script setup>
import { ref, computed, nextTick, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import Modal from '../../../../components/Modal.vue';
import KanbanItemForm from './KanbanItemForm.vue';
import FunnelSelector from './FunnelSelector.vue';
import KanbanSettings from './KanbanSettings.vue';
import BulkDeleteModal from './BulkDeleteModal.vue';
import BulkMoveModal from './BulkMoveModal.vue';
import BulkAddModal from './BulkAddModal.vue';
import KanbanAPI from '../../../../api/kanban';
import KanbanFilter from './KanbanFilter.vue';
import KanbanAI from './KanbanAI.vue';

// Função utilitária de debounce
const debounce = (fn, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(...args), delay);
  };
};

const { t } = useI18n();
const store = useStore();
const emit = defineEmits([
  'filter',
  'settings',
  'item-created',
  'bulk-actions',
  'search',
  'items-updated',
  'switch-view',
]);

const showAddModal = ref(false);
const selectedFunnel = computed(
  () => store.getters['funnel/getSelectedFunnel']
);

const props = defineProps({
  currentStage: {
    type: String,
    default: '',
  },
  searchResults: {
    type: Object,
    default: () => ({ total: 0, stages: {} }),
  },
  columns: {
    type: Array,
    default: () => [],
  },
  activeFilters: {
    type: Object,
    default: null,
  },
  currentView: {
    type: String,
    default: 'kanban',
  },
  kanbanItems: {
    type: Array,
    required: true,
  },
});

const showSettingsModal = ref(false);

const showSearchInput = ref(false);
const searchQuery = ref('');
const showResultsDetails = ref(false);

const showBulkActions = ref(false);

const bulkActions = [
  {
    id: 'add',
    label: t('KANBAN.BULK_ACTIONS_OPTIONS.ADD'),
    icon: 'add',
  },
  {
    id: 'move',
    label: t('KANBAN.BULK_ACTIONS_OPTIONS.MOVE'),
    icon: 'arrow-right',
  },
  {
    id: 'delete',
    label: t('KANBAN.BULK_ACTIONS_OPTIONS.DELETE'),
    icon: 'delete',
  },
];

const showBulkDeleteModal = ref(false);
const showBulkMoveModal = ref(false);
const showBulkAddModal = ref(false);

const showSettingsDropdown = ref(false);

const userIsAdmin = computed(() => {
  const currentUser = store.getters.getCurrentUser;
  return currentUser?.role === 'administrator';
});

const settingsOptions = computed(() => {
  const options = [
    {
      id: 'kanban_settings',
      label: t('KANBAN.SETTINGS_OPTIONS.KANBAN_SETTINGS'),
      icon: 'settings',
    },
  ];

  if (userIsAdmin.value) {
    options.unshift({
      id: 'manage_funnels',
      label: t('KANBAN.SETTINGS_OPTIONS.MANAGE_FUNNELS'),
      icon: 'task',
    });
  }

  return options;
});

const handleSettingsOption = option => {
  if (option.id === 'kanban_settings') {
    showSettingsModal.value = true;
  } else if (option.id === 'manage_funnels') {
    emit('switch-view', 'funnels');
  }
  showSettingsDropdown.value = false;
};

const handleSettingsClick = () => {
  showSettingsDropdown.value = !showSettingsDropdown.value;
};

const handleBulkActions = () => {
  showBulkActions.value = !showBulkActions.value;
};

const selectBulkAction = action => {
  if (action.id === 'delete') {
    showBulkDeleteModal.value = true;
  } else if (action.id === 'move') {
    showBulkMoveModal.value = true;
  } else if (action.id === 'add') {
    showBulkAddModal.value = true;
  }
  showBulkActions.value = false;
};

const showFilterModal = ref(false);

const handleFilterApply = filters => {
  emit('filter', filters);
  showFilterModal.value = false;
};

const handleFilter = () => {
  showFilterModal.value = true;
};

const handleSettings = () => {
  showSettingsModal.value = true;
};

const handleCloseSettings = () => {
  showSettingsModal.value = false;
};

const handleAdd = () => {
  if (!selectedFunnel.value?.id) {
    // TODO: Mostrar mensagem de erro para selecionar um funil primeiro
    console.error('Nenhum funil selecionado');
    return;
  }
  showAddModal.value = true;
};

const handleItemCreated = async item => {
  emit('item-created', item);
  showAddModal.value = false;
};

const handleSearch = () => {
  showSearchInput.value = !showSearchInput.value;
  if (showSearchInput.value) {
    // Aguarda o DOM atualizar para focar no input
    nextTick(() => {
      document.getElementById('kanban-search').focus();
    });
  } else {
    // Limpa a busca ao fechar
    searchQuery.value = '';
    emit('search', '');
  }
};

// Watch para manter o input visível enquanto houver busca
watch(searchQuery, newValue => {
  if (newValue) {
    showSearchInput.value = true;
  }
});

const onSearch = debounce(e => {
  const query = e.target.value;
  searchQuery.value = query;
  emit('search', query);
}, 500);

const handleBulkDelete = async selectedIds => {
  try {
    await Promise.all(selectedIds.map(id => KanbanAPI.deleteItem(id)));
    showBulkDeleteModal.value = false;
    emit('items-updated');
  } catch (error) {
    console.error('Erro ao excluir itens:', error);
  }
};

const handleBulkMove = async ({ itemIds, stageId }) => {
  try {
    await Promise.all(itemIds.map(id => KanbanAPI.moveToStage(id, stageId)));
    showBulkMoveModal.value = false;
    emit('items-updated');
  } catch (error) {
    console.error('Erro ao mover itens:', error);
  }
};

const handleBulkItemsCreated = async () => {
  showBulkAddModal.value = false;
  emit('items-updated');
};

// Função para obter a cor da etapa
const getStageColor = stageName => {
  const column = props.columns.find(col => col.title === stageName);
  return column?.color ? `${column.color}` : '#64748B';
};

const handleMessageTemplates = () => {
  emit('switch-view', 'messageTemplates');
};

// Computed para contar filtros ativos
const activeFiltersCount = computed(() => {
  if (!props.activeFilters) return 0;

  let count = 0;
  if (props.activeFilters.priority?.length) count++;
  if (props.activeFilters.value?.min || props.activeFilters.value?.max) count++;
  if (props.activeFilters.agent_id) count++;
  if (props.activeFilters.date?.start || props.activeFilters.date?.end) count++;

  return count;
});

const handleViewChange = view => {
  emit('switch-view', view);
};

const showAIModal = ref(false);

const handleAIClick = () => {
  showAIModal.value = true;
};
</script>

<template>
  <header class="kanban-header">
    <div class="header-left">
      <button class="kanban-ai-button" @click="handleAIClick">
        <svg
          class="lightning-icon"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M13 3L4 14H12L11 21L20 10H12L13 3Z" fill="currentColor" />
        </svg>
        Kanban AI
      </button>

      <div class="view-toggle-buttons">
        <woot-button
          variant="clear"
          size="small"
          :class="{ active: currentView === 'kanban' }"
          @click="emit('switch-view', 'kanban')"
        >
          <fluent-icon icon="task" size="16" />
        </woot-button>
        <woot-button
          variant="clear"
          size="small"
          :class="{ active: currentView === 'list' }"
          @click="emit('switch-view', 'list')"
        >
          <fluent-icon icon="list" size="16" />
        </woot-button>
        <woot-button
          variant="clear"
          size="small"
          :class="{ active: currentView === 'agenda' }"
          @click="emit('switch-view', 'agenda')"
        >
          <fluent-icon icon="calendar" size="16" />
        </woot-button>
      </div>
      <funnel-selector />
      <div class="search-container" :class="{ 'is-active': showSearchInput }">
        <woot-button variant="clear" size="small" @click="handleSearch">
          <fluent-icon icon="search" size="16" />
        </woot-button>
        <div v-show="showSearchInput" class="search-input-wrapper">
          <input
            id="kanban-search"
            v-model="searchQuery"
            type="search"
            class="search-input"
            :placeholder="t('SEARCH.INPUT_PLACEHOLDER')"
            @input="onSearch"
            @blur="!searchQuery && (showSearchInput = false)"
          />
          <div
            v-if="searchQuery && searchResults.total > 0"
            class="search-results-tags"
          >
            <div class="search-results-tag">
              {{ searchResults.total }} resultado(s)
            </div>
            <div
              v-for="(count, stageName) in searchResults.stages"
              :key="stageName"
              class="search-results-tag"
              :style="{ backgroundColor: getStageColor(stageName) }"
            >
              <span class="stage-name">{{ stageName }}</span>
              <span class="count-badge">{{ count }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-right">
      <div class="bulk-actions-selector">
        <woot-button variant="link" size="small" @click="handleBulkActions">
          <fluent-icon icon="list" size="16" class="mr-1" />
          {{ t('KANBAN.BULK_ACTIONS') }}
          <fluent-icon icon="chevron-down" size="16" class="ml-1" />
        </woot-button>

        <div v-if="showBulkActions" class="dropdown-menu">
          <div
            v-for="action in bulkActions"
            :key="action.id"
            class="dropdown-item"
            @click="selectBulkAction(action)"
          >
            <fluent-icon :icon="action.icon" size="16" class="mr-2" />
            {{ action.label }}
          </div>
        </div>
      </div>
      <woot-button
        variant="clear"
        size="small"
        class="message-templates-btn"
        @click="handleMessageTemplates"
      >
        <fluent-icon icon="document" size="16" class="mr-1" />
        {{ t('KANBAN.MESSAGE_TEMPLATES.TITLE') }}
      </woot-button>
      <woot-button
        variant="clear"
        color-scheme="secondary"
        class="relative"
        @click="$emit('filter')"
      >
        <fluent-icon icon="filter" />
        <span v-if="activeFiltersCount > 0" class="filter-badge">
          {{ activeFiltersCount }}
        </span>
      </woot-button>
      <woot-button
        variant="smooth"
        color-scheme="primary"
        size="small"
        class="add-item-btn"
        @click="handleAdd"
      >
        <fluent-icon icon="add" size="16" class="mr-1" />
      </woot-button>
      <div class="settings-selector">
        <woot-button
          variant="clear"
          size="small"
          @click="handleSettingsClick"
          :title="
            !userIsAdmin ? 'Apenas administradores podem gerenciar funis' : ''
          "
        >
          <fluent-icon icon="more-vertical" size="16" />
        </woot-button>

        <div
          v-if="showSettingsDropdown"
          class="dropdown-menu"
          @mouseleave="showSettingsDropdown = false"
        >
          <div
            v-for="option in settingsOptions"
            :key="option.id"
            class="dropdown-item"
            @click="handleSettingsOption(option)"
          >
            <fluent-icon :icon="option.icon" size="16" class="mr-2" />
            {{ option.label }}
          </div>
        </div>
      </div>
    </div>

    <Modal
      v-model:show="showAddModal"
      :on-close="() => (showAddModal = false)"
      size="large"
    >
      <div class="w-full p-6">
        <h3 class="text-lg font-medium mb-6">
          {{ t('KANBAN.ADD_ITEM') }}
        </h3>
        <KanbanItemForm
          v-if="selectedFunnel"
          :funnel-id="selectedFunnel.id"
          :stage="currentStage"
          @saved="handleItemCreated"
          @close="showAddModal = false"
        />
        <div v-else class="text-center text-red-500">
          {{ t('KANBAN.ERRORS.NO_FUNNEL_SELECTED') }}
        </div>
      </div>
    </Modal>

    <KanbanSettings :show="showSettingsModal" @close="handleCloseSettings" />

    <Modal
      v-model:show="showBulkDeleteModal"
      :on-close="() => (showBulkDeleteModal = false)"
    >
      <BulkDeleteModal
        :items="kanbanItems"
        @close="showBulkDeleteModal = false"
        @confirm="handleBulkDelete"
      />
    </Modal>

    <Modal
      v-model:show="showBulkMoveModal"
      :on-close="() => (showBulkMoveModal = false)"
    >
      <BulkMoveModal
        :items="kanbanItems"
        @close="showBulkMoveModal = false"
        @confirm="handleBulkMove"
      />
    </Modal>

    <Modal
      v-model:show="showBulkAddModal"
      :on-close="() => (showBulkAddModal = false)"
    >
      <BulkAddModal
        :current-stage="currentStage"
        @close="showBulkAddModal = false"
        @items-created="handleBulkItemsCreated"
      />
    </Modal>

    <KanbanFilter
      :show="showFilterModal"
      @close="showFilterModal = false"
      @apply="handleFilterApply"
    />

    <!-- AI Modal -->
    <Modal
      v-model:show="showAIModal"
      :on-close="() => (showAIModal = false)"
      size="large"
      class="ai-modal"
      hide-close-button
    >
      <KanbanAI @close="showAIModal = false" />
    </Modal>
  </header>
</template>

<style lang="scss" scoped>
.kanban-header {
  position: relative;
  z-index: 50;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-normal);
  border-bottom: 1px solid var(--color-border);

  @apply dark:border-slate-800;
}

.header-left {
  display: flex;
  align-items: center;
  gap: var(--space-normal);
}

.header-right {
  display: flex;
  align-items: center;
  gap: var(--space-small);
}

.search-container {
  @apply flex items-center relative;

  &.is-active {
    @apply bg-slate-50 dark:bg-slate-800 rounded-lg;

    .search-input-wrapper {
      width: auto;
      min-width: 300px;
      @apply flex items-center gap-2;
    }
  }
}

.search-input-wrapper {
  @apply overflow-hidden transition-all duration-200;
  width: 0;
}

.search-input {
  @apply h-8 px-2 m-0 text-sm text-slate-800 dark:text-slate-100;

  &:focus {
    @apply outline-none ring-0;
  }

  &::placeholder {
    @apply text-slate-500;
  }
}

.bulk-actions-selector {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 99999;
  min-width: 200px;
  margin-top: var(--space-micro);
  padding: var(--space-micro);
  background: var(--white);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-normal);
  box-shadow: var(--shadow-dropdown);

  @apply dark:bg-slate-800 dark:border-slate-700;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: var(--space-small) var(--space-normal);
  cursor: pointer;
  border-radius: var(--border-radius-small);

  @apply dark:text-slate-100;

  &:hover {
    background: var(--color-background);
    @apply dark:bg-slate-700;
  }
}

.settings-selector {
  position: relative;
  display: inline-block;

  .dropdown-menu {
    right: 0;
    left: auto;
    transform: translateY(4px);
    z-index: 99999;
  }
}

.search-results-tags {
  @apply flex items-center gap-1 flex-wrap;
}

.search-results-tag {
  @apply flex items-center px-2 py-0.5 text-xs font-medium rounded-full
    bg-woot-500 text-white whitespace-nowrap gap-1;

  &:first-child {
    @apply bg-slate-600;
  }

  .stage-name {
    @apply text-[10px];
  }

  .count-badge {
    @apply bg-white/20 px-1.5 rounded-full text-[10px] min-w-[18px] text-center;
  }
}

.message-templates-btn {
  @apply border border-dashed border-woot-500 text-woot-500 hover:bg-woot-50 
    dark:hover:bg-woot-800/20 transition-colors;

  &:hover {
    @apply border-woot-600 text-woot-600;
  }
}

.add-item-btn {
  @apply bg-woot-500 text-white p-2;

  .mr-1 {
    @apply m-0;
  }
}

.filter-badge {
  @apply absolute -top-1 -right-1 min-w-[16px] h-4 px-1
    flex items-center justify-center
    text-[10px] font-medium
    bg-woot-500 text-white
    rounded-full;
}

// Adicione estilos específicos para selects
select {
  @apply bg-white dark:bg-slate-800 cursor-pointer;
  @apply border border-slate-200 dark:border-slate-700;
  @apply rounded-lg px-3 py-2;
  @apply text-slate-800 dark:text-slate-100;
  @apply hover:border-slate-300 dark:hover:border-slate-600;
  @apply focus:ring-2 focus:ring-woot-500/20 focus:border-woot-500;
  @apply disabled:opacity-50 disabled:cursor-not-allowed;
}

.view-toggle-buttons {
  @apply flex border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden;

  .woot-button {
    @apply px-2 py-1.5 border-0 rounded-none;

    &:first-child {
      @apply border-r border-slate-200 dark:border-slate-700;
    }

    &.active {
      @apply bg-slate-100 dark:bg-slate-700 text-woot-500;
    }

    &:hover:not(.active) {
      @apply bg-slate-50 dark:bg-slate-800;
    }
  }
}

.kanban-ai-button {
  @apply flex items-center gap-2 px-3 py-1.5 rounded-lg font-medium text-white text-sm;
  background: linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%);
  transition: all 0.2s ease;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

  &:active {
    transform: translateY(0);
  }

  .lightning-icon {
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1));
  }
}

.ai-modal {
  :deep(.modal__content) {
    @apply w-[85vw] h-[85vh] max-w-[1400px] p-0;
  }
}
</style>
