<script setup>
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();

const dashboardApp = computed(() => {
  const appId = route.params.id;
  return store.getters['dashboardApps/getRecords'].find(
    app => app.id === Number(appId)
  );
});

const appUrl = computed(() => {
  if (!dashboardApp.value?.content?.[0]?.url) return '';
  return dashboardApp.value.content[0].url;
});

onMounted(() => {
  if (!store.getters['dashboardApps/getRecords'].length) {
    store.dispatch('dashboardApps/get');
  }
});
</script>

<template>
  <div class="h-full w-full flex-1 overflow-hidden">
    <iframe
      v-if="appUrl"
      :src="appUrl"
      class="h-full w-full border-0"
      :title="dashboardApp?.title"
    />
    <div
      v-else
      class="h-full w-full flex items-center justify-center text-slate-500"
    >
      {{ $t('INTEGRATION_SETTINGS.DASHBOARD_APPS.LOADING') }}
    </div>
  </div>
</template>
