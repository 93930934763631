<script setup>
import { computed, ref, watch, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import Avatar from 'dashboard/components-next/avatar/Avatar.vue';
import CustomContextMenu from 'dashboard/components/ui/CustomContextMenu.vue';
import Modal from 'dashboard/components/Modal.vue';
import SendMessageTemplate from './SendMessageTemplate.vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  isDragging: {
    type: Boolean,
    default: false,
  },
  draggable: {
    type: Boolean,
    default: false,
  },
  kanbanItems: {
    type: Array,
    default: () => [],
  },
  agentsList: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits([
  'click',
  'edit',
  'delete',
  'dragstart',
  'conversationUpdated',
]);

const router = useRouter();
const { t } = useI18n();

// Debug em desenvolvimento
if (process.env.NODE_ENV === 'development') {
  watch(
    () => props.item,
    newItem => {
      console.group('KanbanItem Props Debug');
      console.log('Item:', newItem);
      console.log('Prioridade:', newItem.priority);
      console.log('Detalhes:', newItem.item_details);
      console.log('Agente:', newItem.item_details?.agent);
      console.log('Valor:', newItem.item_details?.value);
      console.log('Prazo:', newItem.item_details?.deadline_at);
      console.groupEnd();
    },
    { immediate: true, deep: true }
  );
}

// Ref para armazenar os dados da conversa
const conversationData = computed(() => {
  return props.item.item_details?.conversation || null;
});

const priorityInfo = computed(() => {
  // Buscar prioridade do item_details
  const priority = props.item.item_details?.priority?.toLowerCase() || 'none';
  const priorityMap = {
    high: {
      label: t('PRIORITY_LABELS.HIGH'),
      class: 'bg-ruby-50 dark:bg-ruby-800 text-ruby-800 dark:text-ruby-50',
    },
    medium: {
      label: t('PRIORITY_LABELS.MEDIUM'),
      class:
        'bg-yellow-50 dark:bg-yellow-800 text-yellow-800 dark:text-yellow-50',
    },
    low: {
      label: t('PRIORITY_LABELS.LOW'),
      class: 'bg-green-50 dark:bg-green-800 text-green-800 dark:text-green-50',
    },
    none: {
      label: t('PRIORITY_LABELS.NONE'),
      class: 'bg-slate-50 dark:bg-slate-800 text-slate-800 dark:text-slate-50',
    },
  };

  return priorityMap[priority] || priorityMap.none;
});

const formattedDate = computed(() => {
  const dateStr = props.item.created_at || props.item.createdAt;
  if (!dateStr) return '';

  try {
    const date = new Date(dateStr);
    return t('KANBAN.CREATED_AT_FORMAT', {
      date: new Intl.DateTimeFormat('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }).format(date),
    });
  } catch (error) {
    console.error('Erro ao formatar data:', error);
    return '';
  }
});

const formattedValue = computed(() => {
  const value = props.item.item_details?.value;
  const currency = props.item.item_details?.currency;

  if (!value && value !== 0) return null;

  try {
    return new Intl.NumberFormat(currency?.locale || 'pt-BR', {
      style: 'currency',
      currency: currency?.code || 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  } catch (error) {
    return value.toString();
  }
});

const formattedDeadline = computed(() => {
  const deadline = props.item.item_details?.deadline_at;
  const scheduled = props.item.item_details?.scheduled_at;
  const schedulingType = props.item.item_details?.scheduling_type;

  if (!deadline && !scheduled) return null;

  try {
    const dateStr = scheduled || deadline;
    const date = new Date(dateStr);

    if (!Number.isFinite(date.getTime())) {
      return null;
    }

    const dateFormatter = new Intl.DateTimeFormat('pt-BR', {
      dateStyle: 'short',
      ...(schedulingType === 'schedule' && scheduled
        ? { timeStyle: 'short' }
        : {}),
    });

    return dateFormatter.format(date);
  } catch (error) {
    return null;
  }
});

const scheduleIcon = computed(() => {
  const schedulingType = props.item.item_details?.scheduling_type;
  return schedulingType === 'schedule' ? 'calendar' : 'alarm';
});

const truncatedTitle = computed(() => {
  const titleValue = props.item.item_details?.title || '';
  if (titleValue.length > 20) {
    return `${titleValue.substring(0, 20)}...`;
  }
  return titleValue;
});

const truncatedSenderName = computed(() => {
  const name = conversationData.value?.meta?.sender?.name;
  const email = conversationData.value?.meta?.sender?.email;
  const defaultText = t('KANBAN.CONVERSATION_NO_CONTACT');
  const text = name || email || defaultText;

  return text.length > 25 ? `${text.substring(0, 25)}...` : text;
});

const handleClick = () => {
  emit('click', props.item);
};

const handleEdit = e => {
  e.stopPropagation();
  emit('edit', props.item);
};

// Adicionar ref para controlar o modal
const showDeleteModal = ref(false);
const itemToDelete = ref(null);

// Modificar o método de delete para mostrar o modal primeiro
const handleDelete = e => {
  e.stopPropagation();
  itemToDelete.value = props.item;
  showDeleteModal.value = true;
};

// Adicionar método para confirmar a exclusão
const confirmDelete = () => {
  emit('delete', itemToDelete.value);
  showDeleteModal.value = false;
  itemToDelete.value = null;
};

// Função para navegar para a conversa
const navigateToConversation = (e, conversationId) => {
  e.stopPropagation(); // Previne a propagação do clique para o card
  if (!conversationId || !conversationData.value) return;

  try {
    router.push({
      name: 'inbox_conversation_through_inbox',
      params: {
        accountId: conversationData.value.account_id,
        conversationId: conversationId,
      },
    });
  } catch {
    // Fallback: navegação direta pela URL
    window.location.href = `/app/accounts/${conversationData.value.account_id}/conversations/${conversationId}`;
  }
};

// Adicione essas novas refs e funções
const showContextMenu = ref(false);
const contextMenuPosition = ref({ x: 0, y: 0 });

const handleContextMenu = (e, conversationId) => {
  if (!conversationId || !conversationData.value) return;

  e.preventDefault();
  console.log('1. Context Menu aberto');
  showContextMenu.value = true;
  contextMenuPosition.value = {
    x: e.clientX,
    y: e.clientY,
  };
};

const showSendMessageModal = ref(false);

const handleQuickMessage = () => {
  console.log('2. Clicou em Mensagem Rápida');
  try {
    console.log('2.1 Tentando processar clique');
    nextTick(() => {
      showContextMenu.value = false;
      showSendMessageModal.value = true;
    });
    console.log('3. Context Menu fechado:', showContextMenu.value);
    console.log('4. Modal definido como aberto:', showSendMessageModal.value);
  } catch (error) {
    console.error('Erro ao processar clique:', error);
  }
};

const handleSendMessage = ({ template, conversationId }) => {
  console.log('6. Enviando mensagem');
  console.log('Template selecionado:', template);
  console.log('ID da conversa:', conversationId);
  showSendMessageModal.value = false;
  console.log('7. Modal fechado após envio:', showSendMessageModal);
};

const handleViewContact = () => {
  // Fecha o menu de contexto
  showContextMenu.value = false;

  // Verifica se temos os dados necessários
  if (!conversationData.value?.meta?.sender?.id) {
    return;
  }

  try {
    // Navega para a página do contato
    router.push({
      name: 'contact_profile',
      params: {
        accountId: conversationData.value.account_id,
        contactId: conversationData.value.meta.sender.id,
      },
      query: {
        page: 1,
      },
    });
  } catch (err) {
    // Fallback: navegação direta pela URL
    window.location.href = `/app/accounts/${conversationData.value.account_id}/contacts/${conversationData.value.meta.sender.id}?page=1`;
  }
};

const handleDragStart = e => {
  emit('dragstart', e);
};

// Adicionar watch para monitorar mudanças
watch(showSendMessageModal, newValue => {
  console.log('Modal status alterado:', newValue);
  if (!newValue) {
    nextTick(() => {
      showContextMenu.value = false;
    });
  }
});

// Adicione essa nova ref
const showFullDescription = ref(false);

// Adicione esse novo computed
const truncatedDescription = computed(() => {
  const description =
    props.item.item_details?.description || t('KANBAN.NO_DESCRIPTION');
  if (description.length <= 75 || showFullDescription.value) {
    return description;
  }
  return description.substring(0, 75) + '...';
});

// Adicione essa nova função
const toggleDescription = e => {
  e.stopPropagation();
  showFullDescription.value = !showFullDescription.value;
};

const handleFilter = filters => {
  const filteredItems = props.kanbanItems.filter(item => {
    // Lógica de filtro aqui
  });
  emit('filter', filteredItems);
};

// Modificar computed agentInfo
const agentInfo = computed(() => {
  const agentId = props.item.item_details?.agent_id;
  if (!agentId) return null;

  // Buscar o agente na lista recebida via props
  const agent = props.agentsList.find(a => a.id === agentId);

  if (agent) {
    return {
      id: agent.id,
      name: agent.name,
      avatar_url: agent.avatar_url || agent.thumbnail || '',
    };
  }

  return null;
});
</script>

<template>
  <div
    class="flex flex-col p-4 bg-white dark:bg-slate-900 rounded-lg border border-slate-100 dark:border-slate-800 shadow-sm hover:shadow-md transition-shadow cursor-pointer mt-3"
    :class="{ 'opacity-50': isDragging }"
    :draggable="draggable"
    @dragstart="handleDragStart"
    @click="handleClick"
  >
    <div class="flex items-center justify-between mb-3">
      <div class="flex items-center gap-2">
        <h3 class="text-sm font-medium text-slate-900 dark:text-slate-100">
          {{ truncatedTitle }}
        </h3>
        <span
          class="px-2 py-1 text-xs font-medium rounded-full"
          :class="priorityInfo.class"
        >
          {{ priorityInfo.label }}
        </span>
      </div>
      <div class="flex items-center gap-2">
        <button
          class="p-1 text-slate-600 dark:text-slate-400 hover:text-slate-900 dark:hover:text-slate-100"
          @click="handleEdit"
        >
          <fluent-icon icon="edit" size="16" />
        </button>
        <button
          class="p-1 text-slate-600 dark:text-slate-400 hover:text-ruby-600 dark:hover:text-ruby-400"
          @click="handleDelete"
        >
          <fluent-icon icon="delete" size="16" />
        </button>
      </div>
    </div>

    <p class="text-xs text-slate-600 dark:text-slate-400 mb-3">
      {{ truncatedDescription }}
      <span
        v-if="(props.item.item_details?.description || '').length > 75"
        class="text-xs font-medium text-woot-500 dark:text-woot-400 hover:underline cursor-pointer inline-block mt-1"
        @click="toggleDescription"
      >
        {{
          showFullDescription ? t('KANBAN.READ_LESS') : t('KANBAN.READ_MORE')
        }}
      </span>
    </p>

    <!-- Adicionar valor e prazo -->
    <div class="flex items-center justify-between mb-3">
      <div v-if="formattedValue" class="flex items-center gap-1">
        <span class="text-xs font-medium text-slate-700 dark:text-slate-300">
          {{ formattedValue }}
        </span>
      </div>

      <div v-if="formattedDeadline" class="flex items-center gap-1">
        <fluent-icon
          :icon="scheduleIcon"
          class="text-slate-500 dark:text-slate-400"
          size="14"
        />
        <span class="text-xs font-medium text-slate-700 dark:text-slate-300">
          {{ formattedDeadline }}
        </span>
      </div>
    </div>

    <!-- Informação da Conversa -->
    <div
      v-if="item.item_details?.conversation_id && conversationData"
      class="flex items-center gap-2 mb-3 p-2 bg-slate-50 dark:bg-slate-800 rounded-lg border border-slate-100 dark:border-slate-700 min-h-[1.5rem] cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-700 transition-colors"
      @click="navigateToConversation($event, item.item_details.conversation_id)"
      @contextmenu="
        handleContextMenu($event, item.item_details.conversation_id)
      "
    >
      <div class="flex items-center gap-2 flex-1">
        <fluent-icon
          icon="chat"
          class="text-slate-500 dark:text-slate-400 flex-shrink-0"
          size="16"
        />
        <div
          v-if="conversationData"
          class="flex items-center justify-between w-full"
        >
          <p class="text-xs text-slate-700 dark:text-slate-300 truncate my-0">
            #{{ conversationData.id }} - {{ truncatedSenderName }}
            <span
              class="ml-2 text-xs px-2 py-0.5 rounded-full"
              :class="{
                'bg-green-100 text-green-700':
                  conversationData.status === 'open',
                'bg-yellow-100 text-yellow-700':
                  conversationData.status === 'pending',
                'bg-slate-100 text-slate-700':
                  conversationData.status === 'resolved',
              }"
            >
              {{ conversationData.status }}
            </span>
          </p>
          <span
            v-if="conversationData.unread_count > 0"
            class="flex items-center justify-center h-4 min-w-[1rem] px-1 text-[0.625rem] font-medium bg-ruby-500 text-white rounded-full flex-shrink-0"
          >
            {{
              conversationData.unread_count > 9
                ? '9+'
                : conversationData.unread_count
            }}
          </span>
        </div>
        <div
          v-else-if="loadingConversation"
          class="text-xs text-slate-500 flex items-center"
        >
          {{ t('KANBAN.LOADING_CONVERSATION') }}
        </div>
        <div v-else class="text-xs text-slate-500 flex items-center">
          {{ t('KANBAN.CONVERSATION_NOT_FOUND') }}
        </div>
      </div>
    </div>

    <div class="flex items-center justify-between">
      <div class="flex items-center gap-2">
        <div class="flex items-center gap-2">
          <Avatar
            v-if="agentInfo"
            :name="agentInfo.name"
            :src="agentInfo.avatar_url"
            :size="24"
          />
          <span
            v-if="agentInfo"
            class="text-xs text-slate-600 dark:text-slate-400"
          >
            {{ agentInfo.name }}
          </span>
          <span v-else class="text-xs text-slate-400 dark:text-slate-600">
            {{ t('KANBAN.NO_AGENT_ASSIGNED') }}
          </span>
        </div>
      </div>
      <span class="text-[10px] text-slate-500 dark:text-slate-400">
        {{ formattedDate }}
      </span>
    </div>
  </div>

  <!-- Adicionar o ContextMenu -->
  <CustomContextMenu
    v-if="showContextMenu"
    :x="contextMenuPosition.x"
    :y="contextMenuPosition.y"
    :show="showContextMenu"
    @close="showContextMenu = false"
  >
    <div
      class="bg-white dark:bg-slate-800 rounded-lg shadow-lg border border-slate-200 dark:border-slate-700 py-1 w-[180px]"
    >
      <button
        class="w-full px-4 py-2 text-left text-sm hover:bg-slate-50 dark:hover:bg-slate-700 text-slate-700 dark:text-slate-200"
        @click.stop="handleQuickMessage"
        @mousedown.stop
        @mouseup.stop
      >
        <span class="flex items-center gap-2">
          <fluent-icon icon="chat" size="16" />
          {{ t('KANBAN.CONTEXT_MENU.QUICK_MESSAGE') }}
        </span>
      </button>
      <button
        class="w-full px-4 py-2 text-left text-sm hover:bg-slate-50 dark:hover:bg-slate-700 text-slate-700 dark:text-slate-200"
        @click="handleViewContact"
      >
        <span class="flex items-center gap-2">
          <fluent-icon icon="person" size="16" />
          {{ t('KANBAN.CONTEXT_MENU.VIEW_CONTACT') }}
        </span>
      </button>
    </div>
  </CustomContextMenu>

  <!-- Modal de Mensagem Rápida -->
  <Modal
    v-model:show="showSendMessageModal"
    :on-close="() => (showSendMessageModal = false)"
    :show-close-button="true"
    size="medium"
    :close-on-backdrop-click="false"
    :class="{ 'z-50': showSendMessageModal }"
  >
    <div class="settings-modal">
      <header class="settings-header">
        <h3 class="text-lg font-medium">
          {{ t('KANBAN.SEND_MESSAGE.TITLE') }}
        </h3>
      </header>

      <div class="settings-content">
        <SendMessageTemplate
          :conversation-id="item.item_details?.conversation_id"
          :current-stage="item.funnel_stage || ''"
          :contact="conversationData?.meta?.sender"
          :conversation="conversationData"
          :item="item"
          @close="
            () => {
              showSendMessageModal = false;
              showContextMenu.value = false;
            }
          "
          @send="
            data => {
              console.log('SendMessageTemplate @send');
              handleSendMessage(data);
            }
          "
        />
      </div>
    </div>
  </Modal>

  <!-- Modal de Confirmação de Exclusão -->
  <Modal
    v-model:show="showDeleteModal"
    :on-close="
      () => {
        showDeleteModal = false;
        itemToDelete = null;
      }
    "
  >
    <div class="p-6">
      <h3 class="text-lg font-medium mb-4">Confirmar exclusão</h3>
      <p class="text-sm text-slate-600 dark:text-slate-400 mb-6">
        Tem certeza que deseja excluir o item "{{ itemToDelete?.title }}"?
      </p>
      <div class="flex justify-end space-x-2">
        <woot-button
          variant="clear"
          color-scheme="secondary"
          @click="showDeleteModal = false"
        >
          Cancelar
        </woot-button>
        <woot-button
          variant="solid"
          color-scheme="alert"
          @click="confirmDelete"
        >
          Excluir
        </woot-button>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
.flex {
  &:first-child {
    margin-top: 0;
  }
}

.settings-modal {
  @apply flex flex-col;

  .settings-header {
    @apply p-4 border-b border-slate-100 dark:border-slate-700;
  }

  .settings-content {
    @apply p-4 space-y-4;
  }
}
</style>
